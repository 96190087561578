<template>
  <div class="bg-gray-200">
    <div class="container mx-auto grid grid-cols-2 w-full">
      <div class="py-2 text-md">
        <router-link to="/" class="text-green hover:underline">Dashboard</router-link>
        <span class="text-gray-400"> / </span>
        <router-link to="/samples" class="text-green hover:underline">Sample Requests</router-link>
        <span class="text-gray-400"> / </span>
        <span class="font-bold">Create</span>
      </div>
    </div>
  </div>
  <div class="container mx-auto py-5">
    <div v-if="error" class="bg-red-700 text-white font-bold px-3 py-2">{{error}}</div>
    <form @submit.prevent="submit">
      <h2 class="text-3xl font-bold">Create Sample Request</h2>
      <div class="mb-5">
        <label class="text-sm block my-2 font-bold">Name</label>
        <input class="text-sm p-2 w-80 shadow focus:outline-none focus:ring focus:ring-green-400" type="text" v-model="form.name" required>
      </div>
      <div class="mb-5">
        <label class="text-sm block my-2 font-bold">Company Name</label>
        <input class="text-sm p-2 w-80 shadow focus:outline-none focus:ring focus:ring-green-400" type="text" v-model="form.company">
      </div>
      <div class="mb-5">
        <label class="text-sm block my-2 font-bold">Customer</label>
        <input class="text-sm p-2 w-80 shadow focus:outline-none focus:ring focus:ring-green-400" type="text" v-model="form.customer" required>
      </div>
      <h2 class="text-3xl font-bold mt-10">Shipping</h2>
      <div class="flex w-full">
        <div class="mb-5 mr-10">
          <label class="text-sm block my-2 font-bold">Address 1</label>
          <input class="text-sm p-2 w-80 shadow focus:outline-none focus:ring focus:ring-green-400" type="text" v-model="form.address" required>
        </div>
        <div class="mb-5 mr-10">
          <label class="text-sm block my-2 font-bold">City</label>
          <input class="text-sm p-2 w-48 shadow focus:outline-none focus:ring focus:ring-green-400" type="text" v-model="form.city" required>
        </div>
        <div class="mb-5 mr-10">
          <label class="text-sm block my-2 font-bold">State</label>
          <input class="text-sm p-2 w-48 shadow focus:outline-none focus:ring focus:ring-green-400" type="text" v-model="form.state" required>
        </div>
        <div class="mb-5">
          <label class="text-sm block my-2 font-bold">Zip</label>
          <input class="text-sm p-2 w-48 shadow focus:outline-none focus:ring focus:ring-green-400" type="text" v-model="form.zip" required>
        </div>
      </div>
      <div class="flex">
        <div class="mb-5 mr-10">
          <label class="text-sm block my-2 font-bold">Address 2</label>
          <input class="text-sm p-2 w-80 shadow focus:outline-none focus:ring focus:ring-green-400" type="text" v-model="form.address_two">
        </div>
        <div class="mb-5 mr-10">
          <label class="text-sm block my-2 font-bold">Country</label>
          <input class="text-sm p-2 w-48 shadow focus:outline-none focus:ring focus:ring-green-400" type="text" v-model="form.country">
        </div>
      </div>
      <div class="mb-5">
        <label class="text-sm block my-2 font-bold">Notes</label>
        <textarea class="text-sm mt-2 w-1/2 p-2 shadow" v-model="form.notes" rows="4"></textarea>
      </div>
      <h2 class="text-3xl font-bold mt-10">Parts <PlusCircleIcon class="ml-2 inline-block h-7 w-7 text-green cursor-pointer" v-on:click="addPart()" /></h2>
      <div class="flex bg-gray-50 mt-2" v-for="(part, index) in form.parts" v-bind:key="index">
        <div class="mb-5 mr-10 ml-5">
          <label class="text-sm block my-2 font-bold">Part Number</label>
          <input class="text-sm p-2 w-80 shadow focus:outline-none focus:ring focus:ring-green-400" type="text" v-model="part.part_number">
        </div>
        <div class="mb-5 mr-10">
          <label class="text-sm block my-2 font-bold">Annual Usage</label>
          <input class="text-sm p-2 w-48 shadow focus:outline-none focus:ring focus:ring-green-400" type="text" v-model="part.annual_usage">
        </div>
        <div class="mb-5 mr-10">
          <label class="text-sm block my-2 font-bold">Quantity</label>
          <input class="text-sm p-2 w-48 shadow focus:outline-none focus:ring focus:ring-green-400" type="text" v-model="part.quantity">
        </div>
      </div>

      <hr class="block my-5">
      <button class="bg-green w-80 rounded-md text-white p-1 shadow mt-5" type="submit">Create Sample Request</button>
      
    </form>
  </div>
</template>
<script>
import { PlusCircleIcon } from "@heroicons/vue/outline";
import { mapGetters } from "vuex";

export default {
  components: {
    PlusCircleIcon,
  },

  data: () => {
    return {
      form: {
        name: "",
        company: "",
        parts: [
          {
            part_number: "",
            annual_usage: "",
            quantity: "",
          },
        ],
      },
      error: "",
    };
  },

  computed: {
    ...mapGetters({
      sample: "samples/details",
    }),
  },

  methods: {
    async submit() {
      if (!this.form.parts[0].part_number) {
        this.error = "Must have at least one part number";
        return;
      }
      try {
        await this.$store.dispatch("samples/create", this.form);

        this.$router.push(`/samples/${this.sample.id}`);
      } catch (error) {
        this.error = "Error from API";
      }
    },
    addPart() {
      this.form.parts.push({
        part_number: "",
        annual_usage: "",
        quantity: "",
      });
    },
  },
};
</script>